.Logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

h1 {
 font-size: 27px;
 font-family:'Poppins Black';
 margin:0px;
 padding:0px;
 line-height: 1;
 margin-bottom: -3px;
 letter-spacing: 1px;
}

.Logo > .Logo-Subtitle {
 font-size: 6px;
 margin:0px;
 padding:0px;
 text-align: center;
 line-height: 1;
 white-space: nowrap;
}