.Navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 18vh;
    position: relative;
}

.Menu-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 88%;
    height: auto;
}

.Menu-Options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Poppins Light';
}

.Menu-Options > div > h1 {
    font-family: 'Poppins Thin';
    font-size: 17px;
    padding: 0px 15px;
    color: white;
    letter-spacing: 0.5px;
    cursor: pointer;
    transition: 0.5s ease-in all;
}

.Menu-Options > div > h1:hover {
    transition: 0.5s ease-in all;
    opacity: 0.5;
}

.First-Half {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50%;

}

.Emergency {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 6vh;
    
}

.Numbers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.Numbers > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.Numbers > div > h1 {
    font-family: 'Poppins Thin';
    font-size: 17px;
    color: white;
}

.Numbers > div > a > h1 {
    font-family: 'Poppins Thin';
    font-size: 13px;
    color: white;
    text-decoration: none;
}
.Numbers > h1 {
    font-family: 'Poppins Thin';
    font-size: 17px;
    color: white;
}

.Emergency > h1 {
    font-family: 'Poppins Thin';
    font-size: 17px;
    color: white;
}

.Emergency > a > h1 {
    font-family: 'Poppins Thin';
    font-size: 17px;
    color: white;
}

.Mobile-Menu-Container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    height:200px;
    left: 0%;
    top:100%;
    position: absolute;
    z-index: 30;
}

.Mobile-Menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width:90%;
    height: 90%;
}

.Mobile-Menu > h1 {
    font-family: 'Poppins Light';
    font-size: 17px;
    text-align: left;
    cursor:pointer;
}

@media (max-width:1100px) {
    .Emergency {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 7vh;
        
    }
}

@media (max-width:430px) {
    .Navbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;
        position: relative;
    }
    
}