.Our-Mission-Section {
    height:300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.Our-Mission-Section > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 90%;
}

.Mission-Text-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width:50%

}

.Mission-Text-Container > h1 {
    color: #484c50;
    font-family:'Poppins Bold';
    font-size: 35px;
    line-height: 1.2;
}

.Mission-Text-Container > h2 {
    color: #484c50;
    font-family:'Poppins Light';
    font-size: 20px;
    line-height: 1.2;
}

.Core-Values-Container  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width:40%

}

.Core-Values-Container > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.Core-Values-Container > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
}

.Core-Values-Container > h1 {
    color: #484c50;
    font-family:'Poppins Bold';
    font-size: 35px;
    line-height: 1.2;
}

.Core-Values-Container > div > div > h2 {
    color: #484c50;
    font-family:'Poppins Light';
    font-size: 18px;
    line-height: 1.2;
    
}


@media (max-width:1024px) {
    .Core-Values-Container > h1 {
        color: #484c50;
        font-family:'Poppins Bold';
        font-size: 30px;
        line-height: 1.2;
    }
    
    .Core-Values-Container > div > div > h2 {
        color: #484c50;
        font-family:'Poppins Light';
        font-size: 15px;
        line-height: 1.2;
        
    }
    
}


@media (max-width:430px) {
    .Our-Mission-Section {
        height:325px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .Mission-Text-Container > h1 {
        color: #484c50;
        font-family:'Poppins Bold';
        font-size: 22.25px;
        line-height: 1.2;
    }
    
    .Mission-Text-Container > h2 {
        color: #484c50;
        font-family:'Poppins Light';
        font-size: 14px;
        line-height: 1.2;
    }
    .Core-Values-Container > h1 {
        color: #484c50;
        font-family:'Poppins Bold';
        font-size: 22.25px;
        line-height: 1.2;
    }
    
    .Core-Values-Container > div > div > h2 {
        color: #484c50;
        font-family:'Poppins Light';
        font-size: 9px;
        line-height: 1.2;
        
    }
    
}