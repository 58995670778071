.Why-Us-Section {
    height:500px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.Why-Us-Section > div {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Why-Us-Section > div > img {
    height:450px;
    width:auto;

}

.Why-Us-Section > div > div {
    height: 100%;
    width:50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position:relative;
    overflow: hidden;
}

.Why-Us-Section > div > div > .Background-Circle {
    height:325px;
    width:325px;
    border-radius: 50%;
    border-width: 55px;
    border-style: solid;
    position: absolute;
    left: 50%;
    top: -25%;
}

.Why-Us-Section > div > div > div {
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    width:90%;
    z-index: 10;
    height: 80%;
}

.Why-Us-Section > div > div > div > h1 {
    font-family: 'Poppins Bold';
    font-size: 25px;
    text-align: left;
    color: white;
    line-height: 1.3;
    width:95%;
}

.Why-Us-Section > div > div > div > h2 {
    font-family: 'Poppins Thin';
    font-size: 18px;
    text-align: left;
    opacity: 0.90;
    color: white;
    width:95%;
    
}

.Why-Us-Section > div > div > div > h3 {
    font-family: 'Poppins Thin';
    font-size: 15px;
    text-align: left;
    opacity: 0.8;
    color: white;
    width:75%;
    line-height: 1.2;
    
}

@media (max-width:1350px) {
    .Why-Us-Section > div > img {
        height:400px;
        width:auto;
    
    }

    .Why-Us-Section > div > div > div > h1 {
        font-family: 'Poppins Bold';
        font-size: 20px;
        text-align: left;
        color: white;
        line-height: 1.3;
        width:95%;
    }
    
    .Why-Us-Section > div > div > div > h2 {
        font-family: 'Poppins Thin';
        font-size: 15px;
        text-align: left;
        opacity: 0.90;
        color: white;
        width:95%;
        
    }
    
    .Why-Us-Section > div > div > div > h3 {
        font-family: 'Poppins Thin';
        font-size: 13px;
        text-align: left;
        opacity: 0.8;
        color: white;
        width:75%;
        line-height: 1.2;
        
    }



    
}

@media (max-width:1100px) {
    .Why-Us-Section > div > img {
        height:350px;
        width:auto;
    
    }

    .Why-Us-Section > div > div > div > h1 {
        font-family: 'Poppins Bold';
        font-size: 20px;
        text-align: left;
        color: white;
        line-height: 1.3;
        width:95%;
    }
    
    .Why-Us-Section > div > div > div > h2 {
        font-family: 'Poppins Thin';
        font-size: 14px;
        text-align: left;
        opacity: 0.90;
        color: white;
        width:95%;
        
    }
    
    .Why-Us-Section > div > div > div > h3 {
        font-family: 'Poppins Thin';
        font-size: 12px;
        text-align: left;
        opacity: 0.8;
        color: white;
        width:75%;
        line-height: 1.2;
        
    }



    
}

@media (max-width:1024px) {
    .Why-Us-Section {
        height:1100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Why-Us-Section > div {
        width: 100%;
        display: flex;
        height: auto;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: center;
    }

    

    .Why-Us-Section > div > div {
        height: 350px;
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        position:relative;
        overflow: hidden;
    }

    .Why-Us-Section > div > div > .Background-Circle {
        height:240px;
        width:240px;
        border-radius: 50%;
        border-width: 55px;
        border-style: solid;
        position: absolute;
        left: 2.5%;
        top: 0%;
    }
    

    .Why-Us-Section > div > img {
        height:auto;
        width:100%;
    
    }

    .Why-Us-Section > div > div > div {
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        width:95%;
        z-index: 10;
    }

    .Why-Us-Section > div > div > div > h1 {
        font-family: 'Poppins Bold';
        font-size: 30px;
        text-align: left;
        color: white;
        line-height: 1.3;
        width:95%;
    }
    
    .Why-Us-Section > div > div > div > h2 {
        font-family: 'Poppins Thin';
        font-size: 17px;
        text-align: left;
        opacity: 0.75;
        color: white;
        width:95%;
        
    }

    .Why-Us-Section > div > div > div > h3 {
        font-family: 'Poppins Thin';
        font-size: 14px;
        text-align: left;
        opacity: 0.8;
        color: white;
        width:75%;
        line-height: 1.225;
        
    }
}

@media (max-width:1024px) {
    .Why-Us-Section {
        height:900px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Why-Us-Section > div {
        width: 100%;
        display: flex;
        height: auto;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: center;
    }

    

    .Why-Us-Section > div > div {
        height: 525px;
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        position:relative;
        overflow: hidden;
    }

    .Why-Us-Section > div > div > .Background-Circle {
        height:240px;
        width:240px;
        border-radius: 50%;
        border-width: 55px;
        border-style: solid;
        position: absolute;
        left: 2.5%;
        top: 0%;
    }
    

    .Why-Us-Section > div > img {
        height:auto;
        width:100%;
    
    }

    .Why-Us-Section > div > div > div {
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        width:95%;
        z-index: 10;
    }

    .Why-Us-Section > div > div > div > h1 {
        font-family: 'Poppins Bold';
        font-size: 22.25px;
        text-align: left;
        color: white;
        line-height: 1.3;
        width:95%;
    }
    
    .Why-Us-Section > div > div > div > h2 {
        font-family: 'Poppins Thin';
        font-size: 16px;
        text-align: left;
        opacity: 0.75;
        color: white;
        width:95%;
        
    }

    .Why-Us-Section > div > div > div > h3 {
        font-family: 'Poppins Thin';
        font-size: 14px;
        text-align: left;
        opacity: 0.8;
        color: white;
        width:75%;
        line-height: 1.225;
        
    }
}