.How-Can-We-Help {
    height:800px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.How-Can-We-Help > h1 {
    font-family: 'Montserrat Black';
    font-size: 40px;
    text-align: center;
    color: #313c4d;
    line-height: 1.1;
    letter-spacing: 0.25px;
}

.Services-Container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 90%;
}

.Service {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 450px;
    width: 350px;
    cursor: pointer;

}

.Service:hover > div > img {
    transform: scale(1.2);

}

.Service > div > img {
    height:250px;
    width: 350px;
    border-radius: 15px;
    object-fit: cover;
    transition: 0.5s ease-in all;
}

.Service > div {
    overflow: hidden;
    height:250px;
    width: 350px;
    border-radius: 15px;
}



.How-Can-We-Help > .Services-Container > .Service > h2 {
    font-family: 'Poppins Medium';
    font-size: 25px;
    text-align: left;
    color: #313c4d;
    line-height: 1.1;
    letter-spacing: 0.5px;
    text-align: left;
    margin: 0px;
    padding: 0px;
    margin-top: 20px;
}

.How-Can-We-Help > .Services-Container > .Service > h3 {
    font-family: 'Poppins Light';
    font-size: 15px;
    text-align: left;
    color: #313c4d;
    line-height: 1.1;
    text-align: left;
    letter-spacing: 0.5px;
    opacity: 0.75;

}

@media (max-width:1250px) {
    .Service {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        height: 450px;
        width: 300px;
        cursor: pointer;
    
    }
    .Service > div > img {
        height:200px;
        width: 300px;
        border-radius: 15px;
        object-fit: cover;
        transition: 0.5s ease-in all;
    }
    
    .Service > div {
        overflow: hidden;
        height:200px;
        width: 300px;
        border-radius: 15px;
    }

    .How-Can-We-Help > .Services-Container > .Service > h2 {
        font-family: 'Poppins Medium';
        font-size: 20px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.5px;
        text-align: left;
        margin: 0px;
        padding: 0px;
        margin-top: 20px;
    }
    
    .How-Can-We-Help > .Services-Container > .Service > h3 {
        font-family: 'Poppins Light';
        font-size: 13px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        text-align: left;
        letter-spacing: 0.5px;
        opacity: 0.75;
    
    }
    

}


@media (max-width:1024px) {
    .How-Can-We-Help {
        height:2450px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }
    .Services-Container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        width: 90%;
    }

    
    .How-Can-We-Help > h1 {
        font-family: 'Montserrat Black';
        font-size: 35px;
        text-align: center;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.25px;
    }


    .Service {
        display: flex;
        align-items:flex-start;
        justify-content: center;
        flex-direction: column;
        height: auto;
        width: 100%;
        cursor: pointer;
    
    }
    .Service > div > img {
        height:auto;
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
        transition: 0.5s ease-in all;
    }
    
    .Service > div {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height:auto;
        width: 100%;
        border-radius: 15px;
    }

    .How-Can-We-Help > .Services-Container > .Service > h2 {
        font-family: 'Poppins Medium';
        font-size: 25px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 1px;
        text-align: left;
        margin: 0px;
        padding: 0px;
        margin-top: 20px;
    }
    
    .How-Can-We-Help > .Services-Container > .Service > h3 {
        font-family: 'Poppins Light';
        font-size: 15px;
        text-align: left;
        color: #313c4d;
        line-height: 1.2;
        text-align: left;
        letter-spacing: 1px;
        opacity: 0.75;
    
    }

}

@media (max-width:430px) {
    .How-Can-We-Help {
        height:1250px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }
    .Services-Container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        width: 90%;
    }

    
    .How-Can-We-Help > h1 {
        font-family: 'Montserrat Black';
        font-size: 22.25px;
        text-align: center;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.25px;
    }


    .Service {
        display: flex;
        align-items:flex-start;
        justify-content: center;
        flex-direction: column;
        height: 320px;
        width: 95%;
        cursor: pointer;
    
    }
    .Service > div > img {
        height:auto;
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
        transition: 0.5s ease-in all;
    }
    
    .Service > div {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height:auto;
        width: 100%;
        border-radius: 15px;
    }

    .How-Can-We-Help > .Services-Container > .Service > h2 {
        font-family: 'Poppins Medium';
        font-size: 18px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 1px;
        text-align: left;
        margin: 0px;
        padding: 0px;
        margin-top: 20px;
    }
    
    .How-Can-We-Help > .Services-Container > .Service > h3 {
        font-family: 'Poppins Light';
        font-size: 11px;
        text-align: left;
        color: #313c4d;
        line-height: 1.2;
        text-align: left;
        letter-spacing: 1px;
        opacity: 0.75;
    
    }

}