.Send-Message-Section {
    height:1100px;
    display:flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.Send-Message-Info-Container {
    height: 950px;
    width: 612px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.Send-Message-Info-Container > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    height: 50%;
    width: 85%;
}

.Send-Message-Info-Container > div > h1 {
    font-family: 'Poppins Bold';
    font-size: 35px;
    color: white;
    line-height: 1.05;
}

.Send-Message-Info-Container > div > h2 {
    font-family: 'Poppins Bold';
    font-size: 22.5px;
    color: white;
    margin: 0;
    padding: 0;
}

.Send-Message-Info-Container > div > h3 {
    font-family: 'Poppins Thin';
    font-size: 20px;
    color: white;
    opacity:0.75;
    text-align: left;
    line-height: 1.2;
}


.Send-Message-Container {
    height: 950px;
    width: 545px;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 5px 40px;
    z-index:20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.Contact-Information-Container {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    height: 95%;
    width: 90%;
    transition: 0.65s ease-in all;
}

.Contact-Information-Container > h1 {
    font-family: 'Poppins Bold';
    font-size: 35px;
    color: #484c50;
    line-height: 1.05;
}

.Names-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:95%;
}

.Input-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

}

.Name-Input-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

}

.Contact-Input-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 95%;

}

.Contact-Input-Container > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 98%;
}

.Plus-Four-Four-Container {
    border: 5px #cdd1d8 solid;
    background-color: #e0e3ec;
    
    line-height: 1.05;
    opacity: 0.85;
    height: 50px;
    width: 50px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Plus-Four-Four-Container > h1 {
    font-family: 'Poppins Thin';
    font-size: 20px;
    color: #484c50;
    opacity: 0.75;
}


.Type-Input-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 95%;

}

.Message-Input-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 95%;

}

.Name-Input-Container > h2 {
    font-family: 'Poppins Bold';
    font-size: 20px;
    color: #484c50;
    line-height: 1.05;
}

.Contact-Input-Container > h2 {
    font-family: 'Poppins Bold';
    font-size: 20px;
    color: #484c50;
    line-height: 1.05;
}

.Type-Input-Container > h2 {
    font-family: 'Poppins Bold';
    font-size: 20px;
    color: #484c50;
    line-height: 1.05;
}

.Message-Input-Container > h2 {
    font-family: 'Poppins Bold';
    font-size: 20px;
    color: #484c50;
    line-height: 1.05;
}

.Name-Input-Container > input {
    border: 5px #cdd1d8 solid;
    background-color: #e0e3ec;
    font-family: 'Poppins Light';
    font-size: 20px;
    color: #484c50;
    line-height: 1.05;
    opacity: 0.85;
    height: 50px;
    width: 90%;
    border-radius: 3px;

}

.Contact-Input-Container > input {
    border: 5px #cdd1d8 solid;
    background-color: #e0e3ec;
    font-family: 'Poppins Light';
    font-size: 20px;
    color: #484c50;
    line-height: 1.05;
    opacity: 0.85;
    height: 50px;
    width: 95%;
    border-radius: 3px;
}

.Contact-Input-Container > div > input {
    border: 5px #cdd1d8 solid;
    background-color: #e0e3ec;
    font-family: 'Poppins Light';
    font-size: 20px;
    color: #484c50;
    line-height: 1.05;
    opacity: 0.85;
    height: 50px;
    width: 80%;
    border-radius: 3px;
}

.Type-Input-Container > select {
    border: 5px #cdd1d8 solid;
    background-color: #e0e3ec;
    font-family: 'Poppins Light';
    font-size: 20px;
    color: #484c50;
    line-height: 1.05;
    opacity: 0.85;
    height: 50px;
    width: 95%;
    border-radius: 3px;
}

.Message-Input-Container > textarea {
    border: 5px #cdd1d8 solid;
    background-color: #e0e3ec;
    font-family: 'Poppins Light';
    font-size: 20px;
    color: #484c50;
    line-height: 1.05;
    opacity: 0.85;
    height: 150px;
    width: 95%;
    border-radius: 3px;
    resize: none;

}

.Send-Message-Info-Container > .Background-Circle {
    display:none;
}

@keyframes FadeOut {
    from {
        opacity: 1;
    };
    to {
        opacity: 0;
    }
    
}

.FadeOutAnimation {
    animation-name: FadeOut;
    animation-duration: 0.65s;
    opacity: 0;

}

.Thank-You-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 25%;
    text-align:center;
}

.Phone-Auth-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 40%;
    text-align:center;
}

.Phone-Auth-Container > div {
    display:flex;
    flex-direction:row;
    width:100%;
    align-items:center;
    justify-content:space-evenly;
    margin:20px 0px;
}

.Phone-Auth-Container > div > input {
    border: 1px solid #484c50;
    font-family: 'Poppins Light';
    font-size: 20px;
    color: #484c50;
    text-align: center;
    height: 50px;
    width: 50px;
    border-radius: 8px;
}

.Phone-Auth-Container > div > input:focus {
    outline: 2px solid #0e53f0;

}

.Phone-Auth-Container > h2 {
    font-family: 'Poppins Bold';
    font-size: 24px;
    color: #484c50;
    text-align: center;
    margin:0;
    padding:0;
}

.Phone-Auth-Container > h2 > span {
    font-family: 'Poppins Thin';
}


.Phone-Auth-Container > h3 {
    font-family: 'Poppins Thin';
    font-size: 18px;
    color: #484c50;
    opacity:0.75;
    text-align: center;
    line-height: 1.2;
}

.Phone-Auth-Container > button > h3 {
    text-decoration: underline;
    font-family: 'Poppins Thin';
    font-size:15px;
}

.Phone-Auth-Container > button {
    width: 24%;

}

.Phone-Auth-Container > button > span {
    font-family: 'Poppins Bold';
    text-decoration: none;
}

.Thank-You-Container > h1 {
    font-family: 'Poppins Bold';
    font-size: 38px;
    color: #484c50;
    line-height: 1.05;
}

.Thank-You-Container > h2 {
    font-family: 'Poppins Bold';
    font-size: 24px;
    color: #484c50;
    margin: 0;
    padding: 0;
}

@keyframes FadeIn {
    from {
        opacity: 0;
    };
    to {
        opacity: 1;
    }
    
}

.FadeInAnimation {
    animation-name: FadeIn;
    animation-duration: 0.65s;
    opacity: 1;

}

@media (max-width:1024px) {
    .Send-Message-Section {
        height:1350px;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        
    }

    .Send-Message-Info-Container {
        height: 300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
    
    }

    .Send-Message-Info-Container > div {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-direction: column;
        height: 50%;
        width: 85%;
        z-index: 10;
       
    }

    
    .Send-Message-Info-Container > .Background-Circle {
        height:230px;
        width:230px;
        border-radius: 50%;
        border-width: 40px;
        border-style: solid;
        position: absolute;
        left: 5%;
        bottom: 0%;
    }

    .Send-Message-Container {
        height: 900px;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.7) 0px 5px 40px;
        z-index:20;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
    }

    .Contact-Information-Container {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-direction: column;
        height: 95%;
        width: 85%;
        transition: 0.65s ease-in all;
    }

    
}

@media (max-width:430px) {
    
.Contact-Information-Container {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    height: 95%;
    width: 90%;
    transition: 0.65s ease-in all;
}
    .Send-Message-Info-Container > div {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-direction: column;
        height: 65%;
        width: 85%;
    }

    .Send-Message-Info-Container > div > h1 {
        font-family: 'Poppins Bold';
        font-size: 22.25px;
        color: white;
        line-height: 1.05;
    }
    
    .Send-Message-Info-Container > div > h2 {
        font-family: 'Poppins Bold';
        font-size: 17px;
        color: white;
        margin: 0;
        padding: 0;
    }
    
    .Send-Message-Info-Container > div > h3 {
        font-family: 'Poppins Thin';
        font-size: 14px;
        color: white;
        opacity:0.75;
        text-align: left;
        line-height: 1.2;
    }

    .Contact-Information-Container > h1 {
        font-family: 'Poppins Bold';
        font-size: 25px;
        color: #484c50;
        line-height: 1.05;
    }

    .Names-Container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width:100%;
    }

    .Name-Input-Container > input {
        border: 5px #cdd1d8 solid;
        background-color: #e0e3ec;
        font-family: 'Poppins Light';
        font-size: 16px;
        color: #484c50;
        line-height: 1.05;
        opacity: 0.85;
        height: 40px;
        width: 85%;
        border-radius: 3px;
    
    }

    .Contact-Input-Container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    
    }
    
    .Contact-Input-Container > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 96%;
        
    }

    .Contact-Input-Container > input {
        border: 5px #cdd1d8 solid;
        background-color: #e0e3ec;
        font-family: 'Poppins Light';
        font-size: 16px;
        color: #484c50;
        line-height: 1.05;
        opacity: 0.85;
        height: 40px;
        width: 93%;
        border-radius: 3px;
    }

    .Plus-Four-Four-Container {
        border: 5px #cdd1d8 solid;
        background-color: #e0e3ec;
        
        line-height: 1.05;
        opacity: 0.85;
        height: 40px;
        width: 40px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .Plus-Four-Four-Container > h1 {
        font-family: 'Poppins Thin';
        font-size: 16px;
        color: #484c50;
        opacity: 0.75;
    }

    .Contact-Input-Container > div > input {
        border: 5px #cdd1d8 solid;
        background-color: #e0e3ec;
        font-family: 'Poppins Light';
        font-size: 16px;
        color: #484c50;
        line-height: 1.05;
        opacity: 0.85;
        height: 38px;
        width: 70%;
        border-radius: 3px;

    }

    .Type-Input-Container > select {
        border: 5px #cdd1d8 solid;
        background-color: #e0e3ec;
        font-family: 'Poppins Light';
        font-size: 16px;
        color: #484c50;
        line-height: 1.05;
        opacity: 0.85;
        height: 45px;
        width: 95%;
        border-radius: 3px;
    }
    
    .Message-Input-Container > textarea {
        border: 5px #cdd1d8 solid;
        background-color: #e0e3ec;
        font-family: 'Poppins Light';
        font-size: 18px;
        color: #484c50;
        line-height: 1.05;
        opacity: 0.85;
        height: 150px;
        width: 95%;
        border-radius: 3px;
        resize: none;
    
    }
    

    
}


