.Hero-Section {

    height:600px;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.Hero-Section > .Hero-Image-Container > img {
    z-index: 10;
    padding:0px 20px;
}

.Background-Circle {
    height:600px;
    width:600px;
    border-radius: 50%;
    border-width: 100px;
    border-style: solid;
    position: absolute;
    left: -15%;
    bottom: -40%;
}

.Hero-Info-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    height: 90%;
    width: 100%;
}

.Hero-Text-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 10;
    height: 100%;
    width: 70%;
}

.Hero-Text-Container > h1 {
    font-family: 'Poppins Bold';
    font-size: 45px;
    text-align: left;
    color: white;
    line-height: 1.3;
}

.Hero-Text-Container > h2 {
    font-family: 'Poppins Thin';
    font-size: 20px;
    text-align: left;
    opacity: 0.65;
    color: white;
}

.Hero-Image-Container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

@media (max-width:1100px) {
    .Hero-Text-Container > h1 {
        font-family: 'Poppins Bold';
        font-size: 40px;
        text-align: left;
        color: white;
        line-height: 1.3;
    }
    
    .Hero-Text-Container > h2 {
        font-family: 'Poppins Thin';
        font-size: 18px;
        text-align: left;
        opacity: 0.65;
        color: white;
    }
    

    
}

@media (max-width:1024px) {
    .Hero-Section {
        flex-direction: column;
        height:1150px;
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .Hero-Text-Container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        z-index: 10;
        height: 100%;
        width: 100%;
    }

    .Hero-Info-Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 10;
        height: 90%;
        width: 90%;
    }

    .Hero-Image-Container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    .Hero-Section > .Hero-Image-Container > img {
        width: 90%;
        height: auto;
        z-index: 10;
        padding: 0px;
        margin: 0px;

    }

    .Hero-Text-Container > h1 {
        font-family: 'Poppins Bold';
        font-size: 40px;
        text-align: left;
        color: white;
        line-height: 1.3;
    }
    
    .Hero-Text-Container > h2 {
        font-family: 'Poppins Thin';
        font-size: 20px;
        text-align: left;
        opacity: 0.65;
        color: white;
    }
    
}

@media (max-width:430px) {
    .Hero-Section {
        flex-direction: column;
        height:650px;
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .Hero-Text-Container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        z-index: 10;
        height: 90%;
        width: 100%;
    }

    .Hero-Info-Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 10;
        height: 90%;
        width: 85%;
    }

    .Hero-Image-Container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
    }

    .Hero-Section > .Hero-Image-Container > img {
        width: 85%;
        height: auto;
        z-index: 10;
        padding: 0px;
        margin: 0px;

    }

    .Hero-Text-Container > h1 {
        font-family: 'Poppins Bold';
        font-size: 25px;
        text-align: left;
        color: white;
        line-height: 1.3;
    }
    
    .Hero-Text-Container > h2 {
        font-family: 'Poppins Thin';
        font-size: 16px;
        text-align: left;
        opacity: 0.65;
        color: white;
    }
    
}
