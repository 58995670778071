.About-Us-Section {
    height:600px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}

.About-Us-Section > div {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.About-Us-Section > div > img {
    height:400px;
    width:auto;

}

.About-Us-Section > div > div {
    height: 100%;
    width:50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position:relative;
    overflow: hidden;
}

.About-Us-Section > div > div > .Background-Circle {
    height:325px;
    width:325px;
    border-radius: 50%;
    border-width: 55px;
    border-style: solid;
    position: absolute;
    left: -25%;
    top: -25%;
}

.About-Us-Section > div > div > div {
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    width:90%;
    z-index: 10;
}

.About-Us-Section > div > div > div > h1 {
    font-family: 'Poppins Bold';
    font-size: 35px;
    text-align: left;
    color: white;
    line-height: 1.3;
    width:95%;
}

.About-Us-Section > div > div > div > h2 {
    font-family: 'Poppins Thin';
    font-size: 18px;
    text-align: left;
    opacity: 0.75;
    color: white;
    width:95%;
    
}


@media (max-width:1350px) {
    .About-Us-Section > div > img {
        height:350px;
        width:auto;
    
    }

    
.About-Us-Section > div > div > div > h1 {
    font-family: 'Poppins Bold';
    font-size: 30px;
    text-align: left;
    color: white;
    line-height: 1.3;
    width:95%;
}

.About-Us-Section > div > div > div > h2 {
    font-family: 'Poppins Thin';
    font-size: 16px;
    text-align: left;
    opacity: 0.75;
    color: white;
    width:95%;
    
}



}

@media (max-width:1350px) {
    .About-Us-Section > div > img {
        height:300px;
        width:auto;
    
    }

    
.About-Us-Section > div > div > div > h1 {
    font-family: 'Poppins Bold';
    font-size: 28px;
    text-align: left;
    color: white;
    line-height: 1.3;
    width:95%;
}

.About-Us-Section > div > div > div > h2 {
    font-family: 'Poppins Thin';
    font-size: 14px;
    text-align: left;
    opacity: 0.75;
    color: white;
    width:95%;
    
}



}

@media (max-width:1024px) {
    .About-Us-Section {
        height:1100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .About-Us-Section > div {
        width: 100%;
        display: flex;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    

    .About-Us-Section > div > div {
        height: 300px;
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        position:relative;
        overflow: hidden;
    }

    .About-Us-Section > div > div > .Background-Circle {
        height:220px;
        width:220px;
        border-radius: 50%;
        border-width: 40px;
        border-style: solid;
        position: absolute;
        left: 2.5%;
        top: 0%;
    }
    

    .About-Us-Section > div > img {
        height:auto;
        width:100%;
    
    }

    .About-Us-Section > div > div > div {
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        width:95%;
        z-index: 10;
    }

    .About-Us-Section > div > div > div > h1 {
        font-family: 'Poppins Bold';
        font-size: 30px;
        text-align: left;
        color: white;
        line-height: 1.3;
        width:95%;
    }
    
    .About-Us-Section > div > div > div > h2 {
        font-family: 'Poppins Thin';
        font-size: 17px;
        text-align: left;
        opacity: 0.75;
        color: white;
        width:95%;
        
    }
    

}

@media (max-width:430px) {
    .About-Us-Section {
        height:700px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .About-Us-Section > div {
        width: 100%;
        display: flex;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    

    .About-Us-Section > div > div {
        height: 300px;
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        position:relative;
        overflow: hidden;
    }

    .About-Us-Section > div > div > .Background-Circle {
        height:220px;
        width:220px;
        border-radius: 50%;
        border-width: 40px;
        border-style: solid;
        position: absolute;
        left: 2.5%;
        top: 0%;
    }
    

    .About-Us-Section > div > img {
        height:auto;
        width:100%;
    
    }

    .About-Us-Section > div > div > div {
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        width:95%;
        z-index: 10;
    }

    .About-Us-Section > div > div > div > h1 {
        font-family: 'Poppins Bold';
        font-size: 22.25px;
        text-align: left;
        color: white;
        line-height: 1.3;
        width:95%;
    }
    
    .About-Us-Section > div > div > div > h2 {
        font-family: 'Poppins Thin';
        font-size: 13px;
        text-align: left;
        opacity: 0.75;
        color: white;
        width:95%;
        
    }
    

}