.cookie-consent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 70px;
    bottom: 0;
    background-color: white;
    border: #fff 3px solid;
    z-index:30;
}

.cookie-consent > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    height: 100%;
}

.cookie-consent > div > h1 {
    margin: 0;
    padding: 0;
    font-family: 'Poppins Light';
    font-size: 13px;
    color: #252525;
    margin-right: 20px;
}

.cookie-consent > div > button {
    font-family: 'Poppins Light';
    font-size: 13px;
    border-radius: 8px;
    width: 130px;
    height: 40px;
    color: white;
    margin: 0px 10px;

}

@media screen and (max-width: 430px) {
    .cookie-consent > div > h1 {
        margin: 0;
        padding: 0;
        font-family: 'Poppins Light';
        font-size: 9px;
        color: #252525;
        margin-right: 20px;
    }
    
    .cookie-consent > div > button {
        font-family: 'Poppins Light';
        font-size: 10px;
        border-radius: 8px;
        width: 130px;
        height: 40px;
        color: white;
        margin: 0px 10px;
    
    }
    
}

