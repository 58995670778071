.HereToHelp {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    position: relative;
    overflow: hidden;
}

.HereToHelp > .Background-Circle {
    height:300px;
    width:300px;
    border-radius: 50%;
    border-width: 60px;
    border-style: solid;
    position: absolute;
    top: -25%;
    left:40%;
}

.HereToHelp > img {
    height: auto;
    width: 612px;
    z-index: 10;
}

.HereToHelp > div > h1 {
    font-family: 'Poppins Bold';
    font-size: 35px;
    text-align: left;
    color: white;
    line-height: 1.1;
    padding-bottom: 20px;
}

.HereToHelp > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 10;
}

@media (max-width:1100px) { 
    .HereToHelp > div > h1 {
        font-family: 'Poppins Bold';
        font-size: 25px;
        text-align: left;
        color: white;
        line-height: 1.1;
        padding-bottom: 20px;
    }



}


@media (max-width:1024px) { 
    .HereToHelp {
        height: 800px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }

    .HereToHelp > img {
        height: auto;
        width: 100%;
        z-index: 10;
    }

    .HereToHelp > div {
        display: flex;
        height: 300px;
        width: 90%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        z-index: 10;
    }

    .HereToHelp > div > h1 {
        font-family: 'Poppins Bold';
        font-size: 35px;
        text-align: left;
        color: white;
        line-height: 1.1;
        padding-bottom: 20px;
    }

    .HereToHelp > .Background-Circle {
        height:300px;
        width:300px;
        border-radius: 50%;
        border-width: 60px;
        border-style: solid;
        position: absolute;
        top: 60%;
        left:10%;
    }
    

}

@media (max-width:430px) {

    .HereToHelp {
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }

    .HereToHelp > img {
        height: auto;
        width: 100%;
        z-index: 10;
    }

    .HereToHelp > div {
        display: flex;
        height: 300px;
        width: 90%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        z-index: 10;
    }

    .HereToHelp > div > h1 {
        font-family: 'Poppins Bold';
        font-size: 22.25px;
        text-align: left;
        color: white;
        line-height: 1.1;
        padding-bottom: 20px;
    }

    .HereToHelp > .Background-Circle {
        height:300px;
        width:300px;
        border-radius: 50%;
        border-width: 60px;
        border-style: solid;
        position: absolute;
        top: 60%;
        left:10%;
    }
    
    
    
}