.Services-How-Can-We-Help {
    height:2950px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}


.Services-How-Can-We-Help > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height:90%;
    width: 85%;
}

.Services-How-Can-We-Help > div > h1 {
    font-family: 'Montserrat Black';
    font-size: 40px;
    text-align: left;
    color: #313c4d;
    line-height: 1.1;
    letter-spacing: 0.25px;
}

.All-Services-Container {
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.All-Services-Container > .Services-Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    
}

.All-Services-Container > .Services-Container > .Service {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 500px;
    width: 350px;
    cursor: pointer;
}

.All-Services-Container > .Services-Container > .Service:hover > div > img {
    transform: scale(1.2);

}

.All-Services-Container > .Services-Container > .Service > div > img {
    height:250px;
    width: 350px;
    border-radius: 15px;
    object-fit: cover;
    transition: 0.5s ease-in all;
}

.All-Services-Container > .Services-Container > .Service > div {
    overflow: hidden;
    height:250px;
    width: 350px;
    border-radius: 15px;
}



.Services-How-Can-We-Help > div > .All-Services-Container > .Services-Container > .Service > h2 {
    font-family: 'Poppins Medium';
    font-size: 25px;
    text-align: left;
    color: #313c4d;
    line-height: 1.1;
    letter-spacing: 0.5px;
    text-align: left;
    margin: 0px;
    padding: 0px;
    margin-top: 20px;
}

.Services-How-Can-We-Help > div > .All-Services-Container > .Services-Container > .Service > h3 {
    font-family: 'Poppins Light';
    font-size: 15px;
    text-align: left;
    color: #313c4d;
    line-height: 1.1;
    text-align: left;
    letter-spacing: 0.5px;
    opacity: 0.75;

}


@media (max-width:1250px) {
    .All-Services-Container > .Services-Container > .Service {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        height: 475px;
        width: 275px;
        cursor: pointer;
    
    }
    .All-Services-Container > .Services-Container > .Service > div > img {
        height:175px;
        width: 275px;
        border-radius: 15px;
        object-fit: cover;
        transition: 0.5s ease-in all;
    }
    
    .All-Services-Container > .Services-Container > .Service > div {
        overflow: hidden;
        height:175px;
        width: 275px;
        border-radius: 15px;
    }

    .Services-How-Can-We-Help > div > .All-Services-Container > .Services-Container > .Service > h2 {
        font-family: 'Poppins Medium';
        font-size: 23px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.5px;
        text-align: left;
        margin: 0px;
        padding: 0px;
        margin-top: 20px;

    }
    
    .All-Services-Container > .Services-Container > .Service > h3 {
        font-family: 'Poppins Light';
        font-size: 12px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        text-align: left;
        letter-spacing: 0.5px;
        opacity: 0.75;
    
    }
    

}

@media (max-width:1024px) {
    .Services-How-Can-We-Help {
        height:8000px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .Services-How-Can-We-Help > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height:95%;
        width: 85%;
    }

    .All-Services-Container {
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .All-Services-Container > .Services-Container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    
    .Services-How-Can-We-Help > div > h1 {
        font-family: 'Montserrat Black';
        font-size: 35px;
        text-align: center;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.25px;
    }


    .All-Services-Container > .Services-Container > .Service {
        display: flex;
        align-items:flex-start;
        justify-content: center;
        flex-direction: column;
        height: 600px;
        width: 100%;
        cursor: pointer;
    
    }
    .All-Services-Container > .Services-Container > .Service > div > img {
        height:auto;
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
        transition: 0.5s ease-in all;
    }
    
    .All-Services-Container > .Services-Container > .Service > div {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height:auto;
        width: 100%;
        border-radius: 15px;
    }

    .All-Services-Container > .Services-Container > .Service > h2 {
        font-family: 'Poppins Medium';
        font-size: 25px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 1px;
        text-align: left;
        margin: 0px;
        padding: 0px;
        margin-top: 20px;
    }
    
    .All-Services-Container > .Services-Container > .Service > h3 {
        font-family: 'Poppins Light';
        font-size: 15px;
        text-align: left;
        color: #313c4d;
        line-height: 1.2;
        text-align: left;
        letter-spacing: 1px;
        opacity: 0.75;
    
    }
    
}

@media (max-width:430px) {
    .Services-How-Can-We-Help {
        height:5250px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .Services-How-Can-We-Help > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height:95%;
        width: 85%;
    }

    .All-Services-Container {
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .All-Services-Container > .Services-Container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    
    .Services-How-Can-We-Help > div > h1 {
        font-family: 'Montserrat Black';
        font-size: 22.25px;
        text-align: center;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.25px;
    }


    .All-Services-Container > .Services-Container > .Service {
        display: flex;
        align-items:flex-start;
        justify-content: center;
        flex-direction: column;
        height: 350px;
        width: 100%;
        cursor: pointer;
    
    }
    .All-Services-Container > .Services-Container > .Service > div > img {
        height:auto;
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
        transition: 0.5s ease-in all;
    }
    
    .All-Services-Container > .Services-Container > .Service > div {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height:auto;
        width: 100%;
        border-radius: 15px;
    }

    .Services-How-Can-We-Help > div > .All-Services-Container > .Services-Container > .Service > h2 {
        font-family: 'Poppins Medium';
        font-size: 18px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 1px;
        text-align: left;
        margin: 0px;
        padding: 0px;
        margin-top: 20px;
    }
    
    .Services-How-Can-We-Help > div > .All-Services-Container > .Services-Container > .Service > h3 {
        font-family: 'Poppins Light';
        font-size: 11px;
        text-align: left;
        color: #313c4d;
        line-height: 1.2;
        text-align: left;
        letter-spacing: 1px;
        opacity: 0.75;
    
    }
    
}