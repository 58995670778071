.About-Hero-Section {
    height:375px;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.About-Hero-Section > .Background-Circle {
    height:350px;
    width:350px;
    border-radius: 50%;
    border-width: 55px;
    border-style: solid;
    position: absolute;
    left: -7.5%;
    bottom: -30%;
}

.About-Hero-Text-Container {
    width:90%;
    align-items: flex-start;
    justify-content: center;
    z-index:10;
}

.About-Hero-Text-Container > h1 {
    font-family: 'Poppins Bold';
    font-size: 50px;
    text-align: left;
    color: white;
    line-height: 1.3;
}

.About-Hero-Text-Container > h2 {
    font-family: 'Poppins Thin';
    font-size: 18px;
    text-align: left;
    opacity: 0.75;
    color: white;
}


@media (max-width:430px) {
    .About-Hero-Section {
        height:250px;
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .About-Hero-Text-Container > h1 {
        font-family: 'Poppins Bold';
        font-size: 25px;
        text-align: left;
        color: white;
        line-height: 1.3;
    }
    
    .About-Hero-Text-Container > h2 {
        font-family: 'Poppins Thin';
        font-size: 12px;
        text-align: left;
        opacity: 0.75;
        color: white;
    }

    
    .About-Hero-Section > .Background-Circle {
        height:170px;
        width:170px;
        border-radius: 50%;
        border-width: 35px;
        border-style: solid;
        position: absolute;
        left: -7.5%;
        bottom: 0%;
    }

    
}