.Footer {
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.Footer > div > div > div > img {
    height: auto;
    width: 120px;
}

.Footer > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 70%;
}

.Footer > div > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 70%;
}

.Footer > div > div > div  {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
}

.Footer > div > div > div > h1 {
    color: #484c50;
    font-family:'Poppins Bold';
    font-size: 30px;
    padding-bottom: 10px;
}

.Footer > div > div > div > div > h4 {
    color: #484c50;
    font-family:'Poppins Thin';
    font-size: 15px;
    margin: 0;
    padding: 0;
}

.Footer > div > div > div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Footer > div > div > div > div > h1 {
    color: #484c50;
    font-family:'Poppins Thin';
    font-size: 20px;
    line-height: 1.3;}

.Footer > div > div > div > div > h2 {
    color: #484c50;
    font-family:'Poppins Bold';
    font-size: 20px;
    margin: 0;
    padding: 0;
}

.Footer > div > div > div > div > h5 {
    color: #484c50;
    font-family:'Poppins Thin';
    font-size: 20px;
    margin: 0;
    padding: 0;
}
.Footer > div > div > div > div > a > h5 {
    color: #484c50;
    font-family:'Poppins Thin';
    font-size: 20px;
    margin: 0;
    padding: 0;
}



@media (max-width:1024px) {
    .Footer {
        height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .Footer > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 90%;
        height: 70%;
    }
    
    .Footer > div > div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }

    .Footer > div > div > div > div > h2 {
        color: #484c50;
        font-family:'Poppins Bold';
        font-size: 20px;
        margin: 0;
        padding: 0;
    }
    
    
    .Footer > div > div > div > div > h5 {
        color: #484c50;
        font-family:'Poppins Thin';
        font-size: 20px;
        margin: 0;
        padding: 0;
    }
    
}

@media (max-width:430px) {
    .Footer {
        height: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .Footer > div {
        display: flex;

        align-items: center;
        justify-content: flex-start;
        width: 90%;
        height: 100%;
    }

    
    
    .Footer > div > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 90%;
        width: 100%;
    }

    .Footer > div > div > div  {
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 90%;
        margin: 20px 0px
    }

    .Footer > div > div > div > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom:20px
    }
    

    .Footer > div > div > div > div > h2 {
        color: #484c50;
        font-family:'Poppins Bold';
        font-size: 20px;
        margin: 0;
        padding: 0;
    }
    
    
    .Footer > div > div > div > div > h5 {
        color: #484c50;
        font-family:'Poppins Thin';
        font-size: 20px;
        margin-bottom:20px;
        padding: 0;
        width: 200px;
    }

    .Footer > div > div > div > div > h4 {
        color: #484c50;
        font-family:'Poppins Thin';
        font-size: 15px;
        margin: 5px 0px;
        padding: 0;
    }




    
}