.What-Our-Customers-Say {
    height:800px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.What-Our-Customers-Say > h1 {
    font-family: 'Montserrat Black';
    font-size: 40px;
    text-align: center;
    color: #313c4d;
    line-height: 1.1;
    letter-spacing: 0.25px;
}

.Reviews-Container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 90%;
}

.Review {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 225px;
    width: 350px;
}

.Review:hover > div > img {
    transform: scale(1.2);

}

.Review > div > img {
    height:250px;
    width: 350px;
    border-radius: 15px;
    object-fit: cover;
    transition: 0.5s ease-in all;
}





.What-Our-Customers-Say > .Reviews-Container > .Review > h2 {
    font-family: 'Poppins Medium';
    font-size: 25px;
    text-align: left;
    color: #313c4d;
    line-height: 1.1;
    letter-spacing: 0.5px;
    text-align: left;
    margin:0;
    padding: 0;

}

.What-Our-Customers-Say > .Reviews-Container > .Review > h3 {
    font-family: 'Poppins Light';
    font-size: 15px;
    text-align: left;
    color: #313c4d;
    line-height: 1.1;
    text-align: left;
    letter-spacing: 0.5px;
    opacity: 0.75;

}

.What-Our-Customers-Say > .Reviews-Container > .Review > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15px;
    padding-bottom: 10px;

}

.What-Our-Customers-Say > .Reviews-Container > .Review > div > h1 {
    font-family: 'Poppins Medium';
    font-size: 20px;
    text-align: left;
    color: #313c4d;
    line-height: 1.1;
    letter-spacing: 0.5px;
    text-align: left;
    padding: 0;
    padding-right: 5px;
    margin:0;


}

.What-Our-Customers-Say > .Reviews-Container > .Review > div > h4 {
    font-family: 'Poppins Light';
    font-size: 15px;
    text-align: left;
    color: #313c4d;
    line-height: 1.1;
    text-align: left;
    letter-spacing: 0.5px;
    opacity: 0.75;
    margin:0;
    padding: 0;

}



@media (max-width: 1024px) {
    .What-Our-Customers-Say {
        height:1500px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .What-Our-Customers-Say > h1 {
        font-family: 'Montserrat Black';
        font-size: 40px;
        text-align: center;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.25px;
    }

    .What-Our-Customers-Say > .Reviews-Container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        width: 90%;
    }

    .What-Our-Customers-Say > .Reviews-Container > .Review {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        height: auto;
        width: 100%;
    }

    .What-Our-Customers-Say > .Reviews-Container > .Review > h2 {
        font-family: 'Poppins Medium';
        font-size: 25px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.5px;
        text-align: left;
        margin:0;
        padding: 0;
    
    }
    
    .What-Our-Customers-Say > .Reviews-Container > .Review > h3 {
        font-family: 'Poppins Light';
        font-size: 15px;
        text-align: left;
        color: #313c4d;
        line-height: 1.2;
        text-align: left;
        letter-spacing: 0.5px;
        opacity: 0.75;
    
    }
    
    .What-Our-Customers-Say > .Reviews-Container > .Review > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-top: 15px;
        padding-bottom: 10px;
    
    }
    
    .What-Our-Customers-Say > .Reviews-Container > .Review > div > h1 {
        font-family: 'Poppins Medium';
        font-size: 20px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.5px;
        text-align: left;
        padding: 0;
        padding-right: 5px;
        margin:0;
    
    
    }
    
    .What-Our-Customers-Say > .Reviews-Container > .Review > div > h4 {
        font-family: 'Poppins Light';
        font-size: 20px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        text-align: left;
        letter-spacing: 0.5px;
        opacity: 0.75;
        margin:0;
        padding: 0;
    
    }


    




}

@media (max-width:430px) {
    .What-Our-Customers-Say {
        height:1400px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .What-Our-Customers-Say > h1 {
        font-family: 'Montserrat Black';
        font-size: 22.25px;
        text-align: center;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.25px;
    }

    .What-Our-Customers-Say > .Reviews-Container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        width: 90%;
    }

    .What-Our-Customers-Say > .Reviews-Container > .Review {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        height: auto;
        width: 100%;
    }

    .What-Our-Customers-Say > .Reviews-Container > .Review > h2 {
        font-family: 'Poppins Medium';
        font-size: 18px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.5px;
        text-align: left;
        margin:0;
        padding: 0;
    
    }
    
    .What-Our-Customers-Say > .Reviews-Container > .Review > h3 {
        font-family: 'Poppins Light';
        font-size: 12px;
        text-align: left;
        color: #313c4d;
        line-height: 1.2;
        text-align: left;
        letter-spacing: 0.5px;
        opacity: 0.75;
    
    }
    
    .What-Our-Customers-Say > .Reviews-Container > .Review > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-top: 15px;
        padding-bottom: 10px;
    
    }
    
    .What-Our-Customers-Say > .Reviews-Container > .Review > div > h1 {
        font-family: 'Poppins Medium';
        font-size: 18px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        letter-spacing: 0.5px;
        text-align: left;
        padding: 0;
        padding-right: 5px;
        margin:0;
    
    
    }
    
    .What-Our-Customers-Say > .Reviews-Container > .Review > div > h4 {
        font-family: 'Poppins Light';
        font-size: 13px;
        text-align: left;
        color: #313c4d;
        line-height: 1.1;
        text-align: left;
        letter-spacing: 0.5px;
        opacity: 0.75;
        margin:0;
        padding: 0;
    
    }

    
}