@font-face {
  font-family: 'Poppins Black';
  src: url('../public/fonts/Poppins-Black.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('../public/fonts/Poppins-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('../public/fonts/Poppins-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('../public/fonts/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Light';
  src: url('../public/fonts/Poppins-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Thin';
  src: url('../public/fonts/Poppins-Thin.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Thin';
  src: url('../public/fonts/Poppins-ThinItalic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat Black';
  src: url('../public/fonts/Montserrat-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('../public/fonts/Montserrat-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url('../public/fonts/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Light';
  src: url('../public/fonts/Montserrat-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@media (max-width:430px) {
  @font-face {
    font-family: 'Poppins Black';
    src: url('../public/fonts/Inter-Black.otf');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins Bold';
    src: url('../public/fonts/Inter-Bold.otf');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins Medium';
    src: url('../public/fonts/Inter-Medium.otf');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins Regular';
    src: url('../public/fonts/Inter-Regular.otf');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins Light';
    src: url('../public/fonts/Inter-Light-BETA.otf');
    font-weight: normal;
    font-style: normal;
  }
}

.App {
  overflow-x: hidden;
  font-family: 'Poppins Medium';
}