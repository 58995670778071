.White-Button {
    font-family: 'Poppins Medium';
    border-radius: 4px;
    border: none;
    transition: 0.5s ease-in all;
}

.White-Button:hover {
    transition: 0.5s ease-in all;
    opacity: 0.75;
}